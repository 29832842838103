<template>
  <v-container fluid>
    <v-card>
      <v-list-item three-line>
        <v-layout align-center>
          <v-img
            max-height="150"
            max-width="150"
            :src="pieChartPic"
            class="mr-4"
          ></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 black--text">
              CƠ CẤU, TỶ TRỌNG NHÂN SỰ</v-list-item-title
            >
            <v-list-item-subtitle
              >Tổng hợp cơ cấu, tỷ trọng nhân sự theo phòng ban, độ tuổi, giới
              tính ...</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-layout>
      </v-list-item>
    </v-card>
    <v-card class="mt-4" min-height="600px">
      <v-card-title class="pa-6 pb-3">
        <p style="color: black">Tỷ trọng nhân sự</p>
        <v-spacer></v-spacer>
        <div style="width: 400px">
          <v-select
            class="main-chart-select"
            v-model="tyTrongFilter"
            dense
            flat
            single-line
            hide-details
            item-text="name"
            item-value="id"
            :items="filterList"
            outlined
          ></v-select>
        </div>
      </v-card-title>
      <v-card-text class="pa-6 bao-cao-chart">
        <div v-if="tyTrongFilter == 1">
          <ApexChart
            height="450"
            type="pie"
            :options="optionsDoTuoi"
            :series="seriesDoTuoi"
          ></ApexChart>
        </div>
        <div v-if="tyTrongFilter == 2">
          <ApexChart
            height="450"
            type="pie"
            :options="options"
            :series="series"
          ></ApexChart>
        </div>
        <div v-if="tyTrongFilter == 3">
          <ApexChart
            height="450"
            type="pie"
            :options="optionsGioiTinh"
            :series="seriesGioiTinh"
          ></ApexChart>
        </div>
        <div v-if="tyTrongFilter == 4">
          <ApexChart
            height="450"
            type="pie"
            :options="optionsKhuVuc"
            :series="seriesKhuVuc"
          ></ApexChart>
        </div>
        <div v-if="tyTrongFilter == 5">
          <ApexChart
            height="450"
            type="pie"
            :options="optionsSoNam"
            :series="seriesSoNam"
          ></ApexChart>
        </div>
        <div v-if="tyTrongFilter == 6">
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              justify-content: center;
              align-items: center;
            "
          >
            <div
              style="width: 50%; height: 400px; padding-right: 50px"
              v-for="item in dataChartTrinhDo"
              :key="item.id"
            >
              <div
                style="
                  text-align: center;
                  font-size: 17px;
                  color: #1a237e;
                  font-weight: bold;
                "
              >
                {{ item.name }} <span style="font-size: 14px; color: red">({{item.tong}} Người)</span>
              </div>
              <ApexChart
                height="300"
                type="pie"
                :options="item.options"
                :series="item.series"
              ></ApexChart>
              <hr />
            </div>
          </div>
          <!-- <v-row>
            <v-col
              v-for="item in dataChartTrinhDo"
              :key="item.id"
              cols="6"
              md="6"
            >
              <ApexChart
                height="300"
                type="pie"
                :options="item.options"
                :series="item.series"
              ></ApexChart>
            </v-col>
          </v-row> -->
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import ApexChart from "vue-apexcharts";
import {
  soNhanVienTheoDoTuoi,
  soNhanVienTheoGioiTinh,
  soNhanVienTheoKhuVuc,
  soNhanVienTheoNamLamViec,
  soNhanVienTheoTrinhDo,
} from "@/api/nhanvien";
import { getSoNhanSu } from "@/api/phongban";
import pieChartPic from "@/assets/images/piechart.svg";
export default {
  components: {
    ApexChart,
  },
  data: () => ({
    pieChartPic,
    optionsDoTuoi: {},
    seriesDoTuoi: [],
    tyTrongFilter: 1,
    options: {},
    series: [],
    optionsGioiTinh: {},
    seriesGioiTinh: [],
    optionsKhuVuc: {},
    seriesKhuVuc: [],
    optionsSoNam: {},
    seriesSoNam: [],
    dataChartTrinhDo: [],
    filterList: [
      { id: 1, name: "Độ tuổi" },
      { id: 2, name: "Phòng ban" },
      { id: 3, name: "Giới tính" },
      { id: 4, name: "Khu vực địa lý" },
      { id: 5, name: "Số năm làm việc" },
      { id: 6, name: "Trình độ văn hóa" },
    ],
  }),
  created() {
    this.getTyTrongTheoTuoi();
    this.getDanhSachPhongBan();
    this.getTyTrongGioiTinh();
    this.getTyTrongTheoKhuVuc();
    this.getTyTrongTheoNamLamViec();
    this.getTyTrongTrinhDo();
  },
  methods: {
    async getTyTrongGioiTinh() {
      let data = await soNhanVienTheoGioiTinh();
      let gioiTinhLable = ["Nam", "Nữ"];
      let gioiTinhNumber = Object.values(data);
      this.seriesGioiTinh = [...gioiTinhNumber];

      this.optionsGioiTinh = {
        labels: gioiTinhLable,
        series: gioiTinhNumber,
        fill: {
          opacity: 1,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    async getTyTrongTrinhDo() {
      let data = await soNhanVienTheoTrinhDo();
      data.forEach((el) => {
        let trinhDoLable = Object.keys(el.trinh_do);
        let trinhDoNumber = Object.values(el.trinh_do);
        el.series = [...trinhDoNumber];
        trinhDoLable.forEach(function (part, index, theArray) {
          if (theArray[index] == "cao_dang") {
            theArray[index] = "Cao đẳng";
          }
          if (theArray[index] == "dai_hoc") {
            theArray[index] = "Đại học";
          }
          if (theArray[index] == "trung_cap") {
            theArray[index] = "Trung cấp";
          }
          if (theArray[index] == "khong_xac_dinh") {
            theArray[index] = "Không xác định";
          }
        });
        el.options = {
          labels: trinhDoLable,
          series: trinhDoNumber,
          colors: ["#673AB7", "#2196F3", "#6D4C41", "#4CAF50"],
          fill: {
            opacity: 1,
            colors: ["#673AB7", "#2196F3", "#6D4C41", "#4CAF50"],
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        };
      });
      this.dataChartTrinhDo = data;
    },
    async getDanhSachPhongBan() {
      let data = await getSoNhanSu();
      this.phongBans = data.phong_ban;
      this.tongNhanSu = data.tong_nhan_su;
      this.hoatDong = data.hoat_dong;
      this.nghiViec = data.nghi_viec;
      let nhanSuLable = [];
      let nhanSuNumber = [];
      this.phongBans.forEach((el) => {
        nhanSuLable.push(el.name);
        nhanSuNumber.push(el.nhan_su);
      });
      this.series = [...nhanSuNumber];
      this.options = {
        labels: nhanSuLable,
        series: nhanSuNumber,
        colors: [
          "#673AB7",
          "#2196F3",
          "#6D4C41",
          "#4CAF50",
          "#FFEB3B",
          "#E91E63",
          "#607D8B",
        ],
        fill: {
          opacity: 1,
          colors: [
            "#673AB7",
            "#2196F3",
            "#6D4C41",
            "#4CAF50",
            "#FFEB3B",
            "#E91E63",
            "#607D8B",
          ],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    async getTyTrongTheoTuoi() {
      let data = await soNhanVienTheoDoTuoi();
      let doTuoiLable = Object.keys(data);
      let doTuoiNumber = Object.values(data);
      this.seriesDoTuoi = [...doTuoiNumber];
      doTuoiLable.forEach(function (part, index, theArray) {
        if (theArray[index] == "khong_xac_dinh") {
          theArray[index] = "Không xác định";
        }
        if (theArray[index] == "duoi_30") {
          theArray[index] = "Dưới 30 tuổi";
        }
        if (theArray[index] == "tu_30_den_40") {
          theArray[index] = "Từ 30 đến 40 tuổi";
        }
        if (theArray[index] == "tu_41_den_50") {
          theArray[index] = "Từ 41 đến 50 tuổi";
        }
        if (theArray[index] == "tren_50") {
          theArray[index] = "Trên 50 tuổi";
        }
      });
      this.optionsDoTuoi = {
        labels: doTuoiLable,
        series: doTuoiNumber,
        fill: {
          opacity: 1,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    async getTyTrongTheoKhuVuc() {
      let data = await soNhanVienTheoKhuVuc();
      let khuVucLable = Object.keys(data);
      let khuVucNumber = Object.values(data);
      this.seriesKhuVuc = [...khuVucNumber];
      khuVucLable.forEach(function (part, index, theArray) {
        if (theArray[index] == "bac_ninh") {
          theArray[index] = "Bắc Ninh";
        }
        if (theArray[index] == "bac_giang") {
          theArray[index] = "Bắc Giang";
        }
        if (theArray[index] == "cao_bang") {
          theArray[index] = "Cao Bằng";
        }
        if (theArray[index] == "lang_son") {
          theArray[index] = "Lạng Sơn";
        }
        if (theArray[index] == "khac") {
          theArray[index] = "Khu vực khác";
        }
        if (theArray[index] == "khong_xac_dinh") {
          theArray[index] = "Không xác định";
        }
      });
      this.optionsKhuVuc = {
        labels: khuVucLable,
        series: khuVucNumber,
        fill: {
          opacity: 1,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    async getTyTrongTheoNamLamViec() {
      let data = await soNhanVienTheoNamLamViec();
      let namLamViec = Object.keys(data);
      let namLamViecNumber = Object.values(data);
      this.seriesSoNam = [...namLamViecNumber];
      namLamViec.forEach(function (part, index, theArray) {
        if (theArray[index] == "tren_10") {
          theArray[index] = "Từ 10 năm";
        }
        if (theArray[index] == "tu_6_den_9") {
          theArray[index] = "Từ 6 đến 9 năm";
        }
        if (theArray[index] == "tu_4_den_5") {
          theArray[index] = "Từ 4 đến 5 năm";
        }
        if (theArray[index] == "tu_2_den_3") {
          theArray[index] = "Từ 2 đến 3 năm";
        }
        if (theArray[index] == "duoi_1") {
          theArray[index] = "Dưới 2 năm";
        }
        if (theArray[index] == "khong_xac_dinh") {
          theArray[index] = "Không xác định";
        }
      });
      this.optionsSoNam = {
        labels: namLamViec,
        series: namLamViecNumber,
        colors: [
          "#673AB7",
          "#2196F3",
          "#6D4C41",
          "#4CAF50",
          "#FFEB3B",
          "#E91E63",
          "#9C27B0",
        ],
        fill: {
          opacity: 1,
          colors: [
            "#673AB7",
            "#2196F3",
            "#6D4C41",
            "#4CAF50",
            "#FFEB3B",
            "#E91E63",
            "#9C27B0",
          ],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
};
</script>
<style>
.bao-cao-chart .apexcharts-legend-text {
  font-size: 20px !important;
  font-family: "roboto" !important;
}
.bao-cao-chart .apexcharts-legend-marker {
  height: 16px !important;
  width: 16px !important;
  margin-right: 13px;
}
</style>